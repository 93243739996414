import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/components/prism-markdown';
import 'prismjs/themes/prism.css';
import remarkGfm from 'remark-gfm';
import debounce from 'lodash/debounce';
import { Resizable } from 're-resizable';
import { AlertCircle } from 'lucide-react';

// Custom Checkbox component
const Checkbox = ({ checked }) => (
  <input
    type="checkbox"
    checked={checked}
    readOnly
    className="mr-2"
  />
);

// Custom ListItem component
const ListItem = ({ children, ...props }) => {
  if (children && children[0] && typeof children[0] === 'string') {
    const match = children[0].match(/^\[(x| )\]\s(.*)/);
    if (match) {
      return (
        <li {...props} className="list-none">
          <Checkbox checked={match[1] === 'x'} />
          {match[2]}
        </li>
      );
    }
  }
  return <li {...props}>{children}</li>;
};

// Main App component
const App = () => {
  const [notes, setNotes] = useState([]);
  const [selectedNote, setSelectedNote] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(true);
  const [authMode, setAuthMode] = useState('login');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      setShowLoginPopup(false);
      fetchNotes();
    }
  }, []);

  const fetchNotes = async () => {
    const token = localStorage.getItem('token');
    const response = await axios.get('/api/notes', {
      headers: { Authorization: `Bearer ${token}` }
    });
    setNotes(response.data);
  };

  const handleLogin = async (formData) => {
    try {
      const response = await axios.post('/api/login', formData);
      localStorage.setItem('token', response.data.token);
      setIsLoggedIn(true);
      setShowLoginPopup(false);
      fetchNotes();
    } catch (error) {
      console.error('Login failed', error);
      alert('Login failed. Please check your credentials.');
    }
  };

  const handleRegister = async (formData) => {
    try {
      const response = await axios.post('/api/users', formData);
      alert(`Registration successful! Your recovery key is: ${response.data.recoveryKey}. Please store this securely.`);
      setAuthMode('login');
      return { recoveryKey: response.data.recoveryKey };
    } catch (error) {
      console.error('Registration failed', error);
      throw new Error('Registration failed. Please try again.');
    }
  };

  const handlePasswordReset = async (formData) => {
    try {
      const response = await axios.post('/api/reset-password', formData);
      alert(`Password reset successful! Your new recovery key is: ${response.data.newRecoveryKey}. Please store this securely.`);
      setAuthMode('login');
    } catch (error) {
      console.error('Password reset failed', error);
      throw new Error('Password reset failed. Please check your username and recovery key.');
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setNotes([]);
    setSelectedNote(null);
    setShowLoginPopup(true);
    window.location.href = 'https://getnihil.com';
  };

  const handleCreateNote = async () => {
    const token = localStorage.getItem('token');
    const newNote = { content: '# Title', important: false };
    const response = await axios.post('/api/notes', newNote, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setNotes(prevNotes => [response.data, ...prevNotes]);
    setSelectedNote(response.data);
    setIsEditing(true);
  };

  const debouncedUpdateNote = useCallback(
    debounce(async (noteId, updatedContent) => {
      const token = localStorage.getItem('token');
      const response = await axios.put(`/api/notes/${noteId}`,
        { content: updatedContent },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setNotes(prevNotes => {
        const updatedNotes = prevNotes.map(note =>
          note.id === noteId ? { ...note, content: updatedContent, updatedAt: response.data.updatedAt } : note
        );
        return updatedNotes.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
      });
    }, 300),
    []
  );

  const handleUpdateNote = (updatedContent) => {
    if (!selectedNote) return;
    setSelectedNote(prev => ({ ...prev, content: updatedContent }));
    debouncedUpdateNote(selectedNote.id, updatedContent);
  };

  const handleDeleteNote = async (id) => {
    const token = localStorage.getItem('token');
    await axios.delete(`/api/notes/${id}`, {
      headers: { Authorization: `Bearer ${token}` }
    });
    setNotes(notes.filter(note => note.id !== id));
    if (selectedNote && selectedNote.id === id) {
      setSelectedNote(null);
    }
  };

  const filteredNotes = notes.filter(note =>
    note.content.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const openFeedbackForm = () => {
    window.open('https://k4xf3w4vc1w.typeform.com/to/PNr4xM9l', '_blank');
  };

  const sortedNotes = [...filteredNotes].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  // Check if the user is on a mobile device
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // New AuthComponent
  const AuthComponent = () => {
    const [formData, setFormData] = useState({ username: '', password: '', recoveryKey: '' });
    const [recoveryKey, setRecoveryKey] = useState('');

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        if (authMode === 'login') {
          await handleLogin(formData);
        } else if (authMode === 'register') {
          const response = await handleRegister(formData);
          setRecoveryKey(response.recoveryKey);
        } else if (authMode === 'reset') {
          await handlePasswordReset(formData);
        }
      } catch (error) {
        console.error('Operation failed', error);
        alert('Operation failed. Please try again.');
      }
    };

    const renderForm = () => {
      switch (authMode) {
        case 'login':
          return (
            <>
              <h2 className="text-4xl font-extralight mb-6">login</h2>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <button type="submit" className="w-full bg-white text-gray-900 border border-gray-900 py-2 px-4 hover:bg-gray-900 hover:text-white transition-colors duration-300">
                Login
              </button>
            </>
          );
        case 'register':
          return (
            <>
              <h2 className="text-4xl font-extralight mb-6">register</h2>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <button type="submit" className="w-full bg-white text-gray-900 border border-gray-900 py-2 px-4 hover:bg-gray-900 hover:text-white transition-colors duration-300">
                Register
              </button>
              <p className="mt-4 text-sm text-gray-400">
                <AlertCircle className="inline-block mr-1 w-4 h-4" />
                We are committed to data privacy. We don't collect emails or phone numbers. Please securely store your recovery key after registration.
              </p>
            </>
          );
        case 'reset':
          return (
            <>
              <h2 className="text-4xl font-extralight mb-6">reset password</h2>
              <input
                type="text"
                name="username"
                placeholder="Username"
                value={formData.username}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <input
                type="text"
                name="recoveryKey"
                placeholder="Recovery Key"
                value={formData.recoveryKey}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <input
                type="password"
                name="password"
                placeholder="New Password"
                value={formData.password}
                onChange={handleChange}
                className="w-full mb-4 p-2 border border-gray-300 focus:border-gray-600 transition-colors duration-300"
              />
              <button type="submit" className="w-full bg-white text-gray-900 border border-gray-900 py-2 px-4 hover:bg-gray-900 hover:text-white transition-colors duration-300">
                Reset Password
              </button>
            </>
          );
        default:
          return null;
      }
    };

    return (
      <div className="fixed inset-0 bg-white bg-opacity-80 flex justify-center items-center">
        <div className="bg-white p-8 max-w-md w-full">
          <form onSubmit={handleSubmit} className="space-y-4">
            {renderForm()}
          </form>
          <div className="mt-6 flex justify-between text-sm">
            <button
              onClick={() => setAuthMode('login')}
              className={`text-gray-600 hover:text-gray-900 transition-colors duration-300 ${authMode === 'login' ? 'font-semibold' : ''}`}
            >
              login
            </button>
            <button
              onClick={() => setAuthMode('register')}
              className={`text-gray-600 hover:text-gray-900 transition-colors duration-300 ${authMode === 'register' ? 'font-semibold' : ''}`}
            >
              register
            </button>
            <button
              onClick={() => setAuthMode('reset')}
              className={`text-gray-600 hover:text-gray-900 transition-colors duration-300 ${authMode === 'reset' ? 'font-semibold' : ''}`}
            >
              forgot password
            </button>
          </div>
          {recoveryKey && (
            <div className="mt-6 p-4 bg-gray-100 border border-gray-300">
              <h3 className="text-lg font-semibold mb-2">Your Recovery Key</h3>
              <p className="text-sm mb-2">Please store this key securely. You'll need it to reset your password if you forget it.</p>
              <code className="block p-2 bg-white border border-gray-300">{recoveryKey}</code>
            </div>
          )}
        </div>
      </div>
    );
  };

  if (isMobile) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-100 font-sans p-4">
        <p className="text-center text-gray-600 text-sm">
          Please use the desktop version of the app or purchase the mobile app as it helps us afford the hosting costs and the development of new features & tools.
        </p>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100 font-sans">
      {showLoginPopup && <AuthComponent />}
      {isLoggedIn && (
        <Resizable
          defaultSize={{
            width: '90%',
            height: '80vh',
          }}
          minWidth="600px"
          minHeight="400px"
          maxWidth="95%"
          maxHeight="95vh"
          className="bg-white shadow-lg"
        >
          <div className="flex w-full h-full">
            {/* Sidebar */}
            <div className="w-64 border-r border-gray-200 flex flex-col">
              <div className="p-4 flex justify-center">
                <input
                  type="text"
                  placeholder="Search notes"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="w-full p-2 border border-gray-300 text-sm"
                />
              </div>
              <button
                onClick={handleCreateNote}
                className="mx-4 mb-4 bg-white text-gray-900 border border-gray-900 py-2 px-4 font-bold text-sm hover:bg-gray-900 hover:text-white transition-colors duration-300"
              >
                New Note
              </button>
              <div className="overflow-y-auto flex-grow">
                {sortedNotes.map(note => (
                  <div
                    key={note.id}
                    onClick={() => {
                      setSelectedNote(note);
                      setIsEditing(false);
                    }}
                    className={`p-4 border-b border-gray-200 cursor-pointer transition-colors duration-300 ${selectedNote && selectedNote.id === note.id ? 'bg-gray-100' : 'hover:bg-gray-50'}`}>
                    <div className="font-bold mb-1">{note.content.split('\n')[0].replace(/^#+ /, '') || 'New Note'}</div>
                    <div className="text-xs text-gray-600">
                      {note.content.split('\n').slice(1).join(' ').replace(/[#*`]/g, '').substring(0, 50)}...
                    </div>
                  </div>
                ))}
              </div>
              <div className="mt-auto">
                <button
                  onClick={openFeedbackForm}
                  className="mx-4 mb-2 w-[calc(100%-2rem)] bg-white text-black py-2 px-4 font-bold text-sm hover:bg-black hover:text-white border border-black transition-colors duration-300"
                >
                  Submit Feedback
                </button>
                <button
                  onClick={handleLogout}
                  className="mx-4 mb-4 w-[calc(100%-2rem)] bg-black text-white py-2 px-4 font-bold text-sm hover:bg-white hover:text-black border border-black transition-colors duration-300"
                >
                  Logout
                </button>
              </div>
            </div>

            {/* Main content */}
            <div className="flex-grow flex flex-col overflow-hidden">
              {selectedNote ? (
                <div className="h-full flex flex-col overflow-hidden">
                  <div className="flex justify-between items-center p-4 border-b border-gray-200">
                    <h2 className="text-lg font-bold">
                      {isEditing ? 'Edit Note' : 'View Note'}
                    </h2>
                    <div>
                      <button
                        onClick={() => setIsEditing(!isEditing)}
                        className="bg-white text-gray-900 border border-gray-900 py-2 px-4 font-bold text-sm hover:bg-gray-900 hover:text-white transition-colors duration-300 mr-2"
                      >
                        {isEditing ? 'View' : 'Edit'}
                      </button>
                      <button
                        onClick={() => handleDeleteNote(selectedNote.id)}
                        className="bg-black text-white py-2 px-4 font-bold text-sm hover:bg-white hover:text-black border border-black transition-colors duration-300"
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                  <div className="flex-grow overflow-hidden">
                    {isEditing ? (
                      <div className="h-full overflow-auto p-4">
                        <Editor
                          value={selectedNote.content}
                          onValueChange={handleUpdateNote}
                          highlight={code => highlight(code, languages.markdown)}
                          padding={20}
                          placeholder="Start typing here..."
                          className="font-mono text-base bg-white"
                          style={{
                            fontFamily: '"Fira code", "Fira Mono", monospace',
                            fontSize: 16,
                            outline: 0,
                            minHeight: '100%'
                          }}
                        />
                      </div>
                    ) : (
                      <div className="h-full overflow-y-auto p-4">
                        <ReactMarkdown
                          remarkPlugins={[remarkGfm]}
                          components={{
                            li: ListItem
                          }}
                          className="markdown-content"
                        >
                          {selectedNote.content}
                        </ReactMarkdown>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div className="h-full flex items-center justify-center text-gray-600 text-base">
                  Select a note or create a new one
                </div>
              )}
            </div>
          </div>
        </Resizable>
      )}
    </div>
  );
};

export default App;